/**
 * RequestAccess
 */

import React, { Fragment } from 'react';
import styled from 'styled-components';
import { handleCreateUser } from '../services/auth';
import FormGroup from '../components/FormGroup';
import ScrollBar from 'react-overlayscrollbars';
import { cubicBezier, MQ } from '../assets/styles/constants'

class RequestAccess extends React.Component {
  state = {
    email: '',
    username: '',
    password: '',
    form: 'login',
    message: '',
    error: null,
    submitting: false,
    success: false,
  }

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleRequestAccess = event => {
    event.preventDefault();
    const { username, email, password } = this.state;

    const onSuccess = () => {
      this.setState({
        username: '',
        email: '',
        password: '',
        submitting: false,
        form: 'login',
        success: true,
      });
    }

    const onError = error => {
      this.setState({
        error,
        submitting: false,
      });
    }

    if (!username || !email) {
      this.setState({
        error: 'All fields must be completed'
      });
    } else {
      this.setState({
        error: null,
        submitting: true,
      });

      handleCreateUser(username, email, password, onSuccess, onError);
    }

    return false;
  }

  render() {
    const { error, username, email, password, submitting, message, success } = this.state;
    const buttonDisabled = (!username || !email || !password) || submitting;

    return (
      <ScrollBar
        className="os-theme-light"
        id="request-access-scrollbar"
        overflow-behavior={{ x: 'hidden' }}
        scrollbars={{ autoHide: 'l' }}
        style={{height: '100vh'}}
      >
        <Form onSubmit={this.handleRequestAccess} autoComplete={false}>
          { success ? (
            <Fragment>
              <Message>
                <strong>Your request has now been registered!</strong>
                <br />
                <br />
                You will receive an email notification with instructions on accessing the tool.
                <br />
                <br />
                Please remember your log-in details.
              </Message>
            </Fragment>
          ) : (
            <Fragment>
              <Headline>
                Request <strong>Access</strong>
              </Headline>
              <Sub href="#" onClick={() => this.setState({ form: 'login'})}>
                Please register yourself as a user of the CP-3 Web-tool with your e-mail address, username (initials) and password.
              </Sub>
              { message && <Message>{message}</Message> }
              <FormGroup formOnChange={this.handleInputChange('email')} type="email" label="Email" name="email" value={email} autocomplete="none" />
              <FormGroup formOnChange={this.handleInputChange('username')} type="text" label="Username" name="username" value={username} autocomplete="none" />
              <FormGroup formOnChange={this.handleInputChange('password')} type="password" label="Password" name="password" value={password} autocomplete="none" />
              {error && <FormError>Error: {error}</FormError>}
              <FormSubmit disabled={buttonDisabled}>Request</FormSubmit>
            </Fragment>
          )}
        </Form>
      </ScrollBar>
    );
  }
}

export default RequestAccess

const Form = styled.form`
  min-height: 100%;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 20px 80px 20px;
  overflow: auto;
`

const Headline = styled.span`
  font-size: 30px;
  text-transform: uppercase;
  display: block;
  letter-spacing: 4px;
  margin-bottom: 28px;
`

const Message = styled.span`
  font-size: 20px;
  display: block;
  letter-spacing: 0.1rem;
  margin-bottom: 28px;
  max-width: 500px;
  background-color: #FFF;
  color: #000;
  padding: 1rem;
  line-height: 1.3;
`

const Sub = styled.div`
  font-size: 17px;
  display: block;
  max-width: 600px;
  text-align: center;
  line-height: 1.65;
  letter-spacing: 0.1em;
  margin-bottom: 80px;
  padding-bottom: 2px;
`

const FormError = styled.span`
  font-size: 17px;
  text-transform: uppercase;
  display: block;
  letter-spacing: 4px;
`

const FormSubmit = styled.button`
  margin-top: 50px;
  width: 140px;
  height: 140px;
  background: transparent;
  border-radius: 100%;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 0px #fff inset, 0 0 1px 0px #fff;
  line-height: 104px;
  font-size: 13px;
  text-align: center;
  letter-spacing: 4px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.5s ${cubicBezier};
  user-select: none;

  &:disabled {
    opacity: 0.3;
  }

  &:focus {
    outline: none;
  }
  
  @media screen and (${MQ.lg}) {
    &:hover, &:focus {
      opacity: 1;
      transform: scale(1.2);
    }
  }
`
